/**
 * Created by jorg8918 on 3/10/18.
 */

	let boundedSticky = (function() {

		let stickyTarget = document.querySelector('[data-sticky="target"]');
	    if(stickyTarget) {
			let settings = {};
			let paddingTop = null;
			let paddingbottom = null;
			let topOffset = null;
			let step = 'off';
			let topBoundry = document.querySelector('[data-sticky-boundry="top"]');
			let bottomBoundry = document.querySelector('[data-sticky-boundry="bottom"]');

			let stickyTargetH = stickyTarget.offsetHeight;
			let setSettings = function(settings){
				paddingTop = settings.paddingTop;
				paddingbottom = settings.paddingBottom;
				topOffset = settings.minTopOffset;
			}
			let docHeight = document.documentElement.scrollHeight;

			function init(){
				topBoundry = document.querySelector('[data-sticky-boundry="top"]');
				bottomBoundry = document.querySelector('[data-sticky-boundry="bottom"]');
				stickyTarget = document.querySelector('[data-sticky="target"]');
				stickyTargetH = stickyTarget.offsetHeight;
				docHeight = document.documentElement.scrollHeight;
			}

			function fixedToAbsolute(){
				stickyTarget.classList.add('fixed');
				stickyTarget.classList.remove('absolute');
				step ='off';
			}
			function absoluteToFixed(){
				stickyTarget.classList.add('absolute');
				stickyTarget.classList.remove('fixed');
				step ='off';
			}

			function topOperations() {
				var isfixed = topBoundry.getBoundingClientRect().bottom < 0;
				if (isfixed) {
					fixedToAbsolute()
					if(stickyTarget.getAttribute('style')){
						stickyTarget.removeAttribute('style')
					}
				} else {
					if(! stickyTarget.getAttribute('style')){
						topOffset = topBoundry.offsetTop >= topOffset ? topBoundry.offsetTop : topOffset; /* accounts for nav even if it has not being downloaded yet */
						stickyTarget.style.top = (topOffset + topBoundry.offsetHeight + paddingTop) + 'px';
					}
					absoluteToFixed()
				}
			}
			function bottomOperations(){
				var isAbsolute = bottomBoundry.getBoundingClientRect().top  < 120 + stickyTargetH ;
				if (isAbsolute){
					absoluteToFixed();
					if(! stickyTarget.getAttribute('style')){
						stickyTarget.style.top = bottomBoundry.offsetTop - (stickyTargetH + paddingbottom) + 'px';
					}
				} else {
					if(stickyTarget.getAttribute('style')){
						stickyTarget.removeAttribute('style')
					}
					fixedToAbsolute()
				}
			}
			function setOperation(){
				(docHeight/2) > window.pageYOffset ? topOperations() : bottomOperations();
			}

			function initScrollWatcher(currentSettings){
				setSettings(currentSettings);
				setOperation();

				window.addEventListener('scroll', function (){

					if (step === 'off'){
						setTimeout(function(){
							step = 'on';
							setOperation();
						}, 100);
					}
				})

				window.addEventListener('resize', function () {
					init();
					stickyTarget.style.top =  (topBoundry.offsetTop + topBoundry.offsetHeight + paddingTop) + 'px';
				})
			}

			return {
				init: init,
				initScrollWatcher : initScrollWatcher
			}
		}
	}());


/* v.fdr */
