// has a dependency on fed_powers




document.addEventListener("DOMContentLoaded", function(event) {
	var showSummaryLinks = fed_powers.get_arr( '[data-show-summary]' );
	toggleShowSummary(showSummaryLinks, "add");

	var hideSummaryLinks = fed_powers.get_arr( '[data-hide-summary]' );
	toggleShowSummary(hideSummaryLinks, "remove");

	function toggleShowSummary(linkArr, action){
		if(linkArr.length > 0) {
			linkArr.forEach(function(showlink){
				showlink.addEventListener("click", function (e) {
					e.stopPropagation();
					this.parentNode.parentNode.classList[action]('show-summary');
				});
			})
		}

	}

});
