/*
 * FED general and common utility functions used for Newsroom
 * Feel free to add your own following below pattern 2.
 *
 */

let fed_powers = (function () {


	function get_arr($nodes) {

		return (typeof $nodes === 'string') ? Array.prototype.slice.call(document.querySelectorAll($nodes)) : Array.prototype.slice.call($nodes);
	}

	function get_first($nodes) {
		// return only the first element
		// use only when you know there is only one
		var firstElm = (typeof $nodes === 'string') ? Array.prototype.slice.call(document.querySelectorAll($nodes)) : Array.prototype.slice.call($nodes);
		return firstElm[0];
	}

	function isAnyPartOfElementInViewport(el) {

		const rect = el.getBoundingClientRect();
		// DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
		const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
		const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

		// http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
		const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
		const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

		return (vertInView && horInView);
	}


	return {
		'get_arr': get_arr,
		'get_first_from': get_first,
		'isAnyPartOfElementInViewport': isAnyPartOfElementInViewport
	}

})();

