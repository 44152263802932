// Show hide card summaries on tablet/mobile

var showSummaryLinks = Array.prototype.slice.call(document.querySelectorAll('.n-gateway-card-show-summary'));
toggleShowSummary(showSummaryLinks, "add");

var hideSummaryLinks = Array.prototype.slice.call(document.querySelectorAll('.n-gateway-card-hide-summary'));
toggleShowSummary(hideSummaryLinks, "remove");

function toggleShowSummary(linkArr, action) {

	if (linkArr.length > 0) {
		linkArr.forEach(function (showlink) {
			showlink.addEventListener("click", function () {
				this.parentNode.parentNode.classList[action]('show-summary');
				event.stopPropagation();
			});
		})
	}

}


// Podcast Player

var player = document.getElementById('music'); // id for audio element

if (player) {
	var audioPlayer = (function () {
		var podcast = '';

		/*********** public functions ***********/

		function init($player) {
			podcast = $player
			// play button event listenter
			pButton.addEventListener("click", play);

			// timeupdate event listener
			podcast.addEventListener("timeupdate", timeUpdate, false);

			// makes timeline clickable
			timeline.addEventListener("click", function (event) {
                moveplayhead(event);
				podcast.currentTime = duration * clickPercent(event);
			}, false);

			// makes playhead draggable
			playhead.addEventListener('mousedown', mouseDown, false);
			window.addEventListener('mouseup', mouseUp, false);

			audioVolume.addEventListener("mouseover", function () {
				setOriginalVolume()
			}, false);

			volumeSlider.addEventListener("click", function (e) {
				updateVolume(e);

			}, false);

			// Gets audio file duration
			podcast.addEventListener("canplaythrough", function () {
				duration = podcast.duration;
			}, false);
		}


		/*********** private properties **************/
		//Internal var

		var duration = podcast.duration, // Duration of audio clip, calculated here for embedding purposes
			pButton = document.getElementById('pButton'),// play button
			playhead = document.getElementById('playhead'), // playhead
			timeline = document.getElementById('timeline'), // timeline
			durationElm = Array.prototype.slice.call(document.querySelectorAll('#audioplayer .duration'))[0], // duration span
			currentTimeElm = Array.prototype.slice.call(document.querySelectorAll('#audioplayer .current-time'))[0],
			audioVolume = Array.prototype.slice.call(document.querySelectorAll('.audio-volume'))[0],
			volumeSlider = Array.prototype.slice.call(audioVolume.querySelectorAll('.volume-slider'))[0],
            volumeState = Array.prototype.slice.call(audioVolume.querySelectorAll('.volume-state'))[0];
            
        if (duration === Infinity) {
            podcast.currentTime = 24 * 60 * 60;
            podcast.ontimeupdate = function () {
                duration = podcast.duration;
            }
        }

		// Boolean value so that audio position is updated only when the playhead is released
		var onplayhead = false;

		// timeline width adjusted for playhead
		var timelineWidth = timeline.offsetWidth - playhead.offsetWidth;

		/******** private functions ************/
		// mouseDown EventListener
		function mouseDown() {
			onplayhead = true;
			window.addEventListener('mousemove', moveplayhead, true);
			podcast.removeEventListener('timeupdate', timeUpdate, false);
		}

		// mouseUp EventListener
		// getting input from all mouse clicks
		function mouseUp(event) {
			if (onplayhead == true) {
				moveplayhead(event);
				window.removeEventListener('mousemove', moveplayhead, true);
				// change current time
				podcast.currentTime = duration * clickPercent(event);
				podcast.addEventListener('timeupdate', timeUpdate, false);
			}
			onplayhead = false;
		}
		// mousemove EventListener
		// Moves playhead as user drags
		function moveplayhead(event) {
			var newMargLeft = event.clientX - getPosition(timeline);

			if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
				playhead.style.width = newMargLeft + "px";
			}
			if (newMargLeft < 0) {
				playhead.style.width = "0px";
			}
			if (newMargLeft > timelineWidth) {
				playhead.style.width = timelineWidth + "px";
			}
		}

		// timeUpdate
		// Synchronizes playhead position with current point in audio
		function timeUpdate() {
			var playPercent = timelineWidth * (podcast.currentTime / duration);
			playhead.style.width = playPercent + "px";
			currentTimeElm.innerHTML = timeFormat(podcast.currentTime);
			if (podcast.currentTime == duration) {
				pButton.className = "";
				pButton.className = "play";
			}
		}

		//Play and Pause
		function play() {
			// start podcast
			durationElm.innerHTML = timeFormat(podcast.duration);
			if (podcast.paused) {
				podcast.play();
				// remove play, add pause
				pButton.className = "";
				pButton.className = "pause-btn";
			} else { // pause music
				podcast.pause();
				// remove pause, add play
				pButton.className = "";
				pButton.className = "play-btn play";
			}
		}

		function timeFormat(time) {
			// Hours, minutes and seconds
			var hrs = Math.floor(time / 3600);
			var mins = Math.floor((time % 3600) / 60);
			var secs = Math.floor(time % 60);

			// Output like "1:01" or "4:03:59" or "123:03:59"
			var ret = "";

			if (hrs > 0) {
				ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
			}

			ret += "" + mins + ":" + (secs < 10 ? "0" : "");
			ret += "" + secs;
			return ret;
		}

		// getPosition
		// Returns elements left position relative to top-left of viewport
		function getPosition(el) {
			return el.getBoundingClientRect().left;
		}

		function setOriginalVolume() {
			volumeState.style.height = Math.floor(podcast.volume * 100) + "%";
		}

		function updateVolume(e) {
			var clickY = e.clientY - volumeSlider.getBoundingClientRect().top;
			podcast.volume = (100 - clickY) / 100;
			volumeState.style.height = Math.floor(100 - clickY) + "%";
		}

		// returns click as decimal (.77) of the total timelineWidth
		function clickPercent(event) {
			return (event.clientX - getPosition(timeline)) / timelineWidth;
		}


		/****************** return obj **************/
		return {
			init: init
		}
	}());
	audioPlayer.init(player);
}

// sticky share component
document.addEventListener("DOMContentLoaded", function (event) {
	var container;


	// social media nav
	Array.prototype.forEach.call(
		document.querySelectorAll('.news-share.legacy'),
		function (target) {
			// containing element and its bottom distance from the top of the document
			container = document.getElementsByTagName("body")[0];

			var offset = 90;

			// target top distance from the top of the document
			var targetTop = target.getBoundingClientRect().top + window.pageYOffset - offset;
			var targetBottom = container.getBoundingClientRect().bottom + window.pageYOffset - target.getBoundingClientRect().height - offset;
			var targetOffset = container.getBoundingClientRect().height - target.getBoundingClientRect().height;

			// whether the sticky target is absolute, fixed, or relative
			var state = 'absolute';

			// activate element
			window.addEventListener('scroll', onscroll);

			onscroll();

			function onscroll() {
				if (window.pageYOffset < targetTop) {
					if (state !== 'before') {
						target.setAttribute('data-stuck', state = 'before');
						target.style.top = '';
					}
				} else if (window.pageYOffset >= targetBottom) {
					if (state !== 'after') {
						target.setAttribute('data-stuck', state = 'after');
						target.style.top = targetOffset + "px";
					}
				} else if (state !== 'during') {
					target.setAttribute('data-stuck', state = 'during');
					target.style.top = '';
				}
			}

			Array.prototype.slice.call(
				document.querySelectorAll('[data-sharing]')
			).forEach(function (control) {
				control.addEventListener('click', function () {
					console.log('1');
					event.preventDefault(event);
					popupOpen(control.getAttribute('data-sharing'));
				});
			});

			function popupOpen(url) {
				var width = 500;
				var height = 300;
				var left = screen.width / 2 - width / 2;
				var top = screen.height / 2 - height / 2;

				window.open(
					url,
					'',
					'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' + width + ',height=' + height + ',top=' + top + 'left=' + left
				);
			}
		});

	// Search label
	[].forEach.call(document.querySelectorAll('.n-gateway-search-label'), function (tag) {
		tag.innerHTML = "Search";
	});

	[].forEach.call(document.querySelectorAll('[data-search-tag]'), function (tag) {
		let tagString = tag.getAttribute('data-search-tag');
		let searchLabel = tag.querySelector('.n-gateway-search-label');
		searchLabel.innerHTML = tagString;
	});

	//Dynamic heading with carousel
	var $characterLength = 59;
	[].forEach.call(document.querySelectorAll('.n-gateway-carousel-article-headline'), function (item) {
		if (item.textContent.length > $characterLength) {
			item.classList.add('long-heading');
		}
	});

});

// Gallery navigation
// extending Jon Nail's gallery
// add image icons to gallery nav

var galleryNav = function () {
	var curr_gall = null;

	function set_gallery(gallery) {
		curr_gall = gallery;
	}

	function get_gall_nav_buttons() {
		var gall_id = curr_gall.getAttribute('id'),
			gall_label = 'nav[aria-labelledby="' + gall_id + '"]',
			gall_nav = document.querySelector(gall_label);
		return Array.prototype.slice.call(gall_nav.querySelectorAll('button'));

	}

	function get_gall_slide_images() {
		var gall_images = Array.prototype.slice.call(curr_gall.querySelectorAll('.n-gallery-image-src'));
		var img_src_arr = gall_images.map(function (img) {
			return img.getAttribute('src');
		});
		return img_src_arr;
	}

	function set_gall_image_icons() {
		var gall_nav_buttons = get_gall_nav_buttons(),
			gall_slides = get_gall_slide_images();

		gall_nav_buttons.forEach(function (button, index) {
			button.style.backgroundImage = "url('" + gall_slides[index] + "')";
		})
	}

	function init(gallery) {
		set_gallery(gallery);
		set_gall_image_icons();
	}

	return {
		init: init
	}
}();

document.addEventListener("DOMContentLoaded", function (event) {
	var galleryArr = Array.prototype.slice.call(document.querySelectorAll('.n-gallery'));

	if (galleryArr.length > 0) {
		galleryArr.forEach(function (thisGallery) {
			galleryNav.init(thisGallery);
		});
	}
});
