const syncCardsHeight = (noImgCardArr, normalCardArr) => {
	let allCards = noImgCardArr.concat(normalCardArr);
	const removeStyles = () => allCards.forEach(card => card.removeAttribute("style"));

	const getTallestCardHeight = () => {
		let startingHeight = 0;
		allCards.forEach(card => {
			let cardH = card.offsetHeight;
			cardH > startingHeight ? startingHeight = cardH : null;
		});
		return startingHeight;
	};

	// operation
	const adjustHeight = () => {
		let heightCard = getTallestCardHeight();
		allCards.forEach(card => card.style.height = `${heightCard}px`);
	};

	window.addEventListener('resize', () => {
		// adjust height only on desktop
		if(window.innerWidth > 860 ){
			adjustHeight();
		} else {
			removeStyles();
		}
	});

	// adjust height if needed onload
	if(window.innerWidth > 860 ){
		adjustHeight();
	}

};

document.addEventListener("DOMContentLoaded", function(event) {
	// Search for cards
	const noImgCardArr = [].slice.call(document.querySelectorAll('.newsr-card.no-figure'));
	const normalCardArr = [].slice.call(document.querySelectorAll('.newsr-card:not(.no-figure)'));
	// check to see if imageless cards are present
	if (noImgCardArr.length > 0 ){
		syncCardsHeight(noImgCardArr, normalCardArr);
	}
});

/* v.1.xcvxcv */

