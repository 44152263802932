class Utils {  
  debounce (fn, time) {
    let timeout;
  
    return function() {
      const functionCall = () => fn.apply(this, arguments);
      
      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    };
  }

  throttle (func, limit) {
    let lastFunc;
    let lastRan;
    return () => {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(() => {
          if ((Date.now() - lastRan) >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }

  triggerEvent(el, eventName, options) {
    let event;
    let opt = options;
    if (!opt) {
      opt = {
        detail: {
          message: "Custom Event",
          time: new Date()
        },
        bubbles: true,
        cancelable: true
      };
    }
		if (window.CustomEvent && typeof window.CustomEvent === "function") {
			event = new window.CustomEvent(eventName, opt);
		} else {
			event = document.createEvent('CustomEvent');
			event.initCustomEvent(eventName, true, true, opt);
		}
		el.dispatchEvent(event);
	}

  select (sizzle, parent) {
    if (!(typeof sizzle === 'string' || sizzle instanceof String)) {
      console.log('Problem: esriClientUtils.select() requires that the sizzle value passed in is a string.');
      return null;
    } else {
      let elem;
      if (parent) {
        elem = parent.querySelector(sizzle);
      } else {
        elem = document.querySelector(sizzle);
      }
    
      return elem;
    }
  }

  selectAll (sizzle, parent) {
    if (!(typeof sizzle === 'string' || sizzle instanceof String)) {
      console.log('Problem: esriClientUtils.selectAll() requires that the sizzle value passed in is a string.');
      return [];
    } else {
      let elems;
      if (parent) {
        elems = parent.querySelectorAll(sizzle);
      } else {
        elems = document.querySelectorAll(sizzle);
      }

      return [].slice.call(elems);
    }
  }

  findAncestor (el, cls) {
    let elem = el;
    while ((elem = elem.parentElement) && !elem.classList.contains(cls));
    return elem;
  }
  
  getOffset (el) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
  }
  
  fadeOut (el, callback) {
    let op = 1;  // initial opacity
    const timer = window.setInterval(() => {
        if (op <= 0.1) {
            window.clearInterval(timer);
            el.style.display = 'none';
            if (callback) {
              callback();
            }
        }
        el.style.opacity = op;
        el.style.filter = `alpha(opacity=${op * 100})`;
        op -= op * 0.1;
    }, 10);
  }
};

// export const utils = new Utils();
// export default utils;
window.esriClientUtils = new Utils(); 
 